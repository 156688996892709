"use client";
import { sendPv } from "@/tracks/25223";
import { useEffect } from "react";
export default function PvModule({ pageCode, trackingId }: any) {
  useEffect(() => {
    if (pageCode === "home") {
      sendPv.Home_Main();
    } else {
      // sendPvByTrackingId(trackingId);
    }
  }, []);
  return null;
}
